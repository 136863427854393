<template>
    <div class="home">
        <!-- 面包屑 -->
        <!-- <div class="breadcrumb">
            <el-breadcrumb>
                <el-breadcrumb-item :to="{ path: '/hoveContent' }">数控机床一站式服务平台首页</el-breadcrumb-item>
                <el-breadcrumb-item>全部商品</el-breadcrumb-item>
                <el-breadcrumb-item>仪器</el-breadcrumb-item>
                <el-breadcrumb-item>电机</el-breadcrumb-item>
            </el-breadcrumb>
        </div> -->
        <div v-loading="loading">
            <!-- 无结果 -->
            <div v-if="goodsList?.length == 0 && !loading" style="display: flex;justify-content: center;margin-top: 100px;">
                <noResults :keyWords="keyWords" :typeSouS="typeSouS"></noResults>
            </div>

            <div v-else>

                <!-- tab -->
                <div style="position: relative;" v-if="!typeSouS">
                    <div class="brandDead" v-loading="loading2" ref="brandDead">
                        <div v-if="typeList?.length>2" style="width: 80px;height: 25px;position: absolute; bottom: -30px;left: 50%;transform: translateX(-50%);"
                            @click="aFold">
                            <img :src="zkImg" style="width: 100%;height: 100%;object-fit: cover;" alt="">
                        </div>
                        <div class="son">
                            <div class="title">关键字</div>
                            <div class="tabs">
                                <div class="tab" style="color:red">{{ keyWords }}{{ ` ${keyWords2}` }}</div>
                            </div>
                            <div @click="screen"
                                style="width: 100px;height: 30px;background-color: #ff4242;color:#fff;border-radius: 5px;text-align: center;line-height: 30px;cursor: pointer">
                                确定</div>
                        </div>
                        <div class="son" v-for="(i, index) in typeList" :key="index">
                            <div class="title">{{ i.title }}</div>
                            <div class="tabs">
                                <div class="tab" :class="[d.choose ? 'tabsAct' : '']" v-for="(d, ind) in i.option"
                                    :key="ind" @click="tabsClick(i, d)">{{ d.label }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 价格排序 tabs -->
                <div class="sorting">
                    <div class="tabsBox">
                        <div class="tabs" :style="{ width:( sortList.length || 0) * 84 + 'px' }">
                            <div class="son" :class="[i.act ? 'tabsAct2' : '']" @click="sortCilck(index)"
                                :style="{ borderLeft: index == 1 || index == 3 ? '1px solid #e0e0e0' : '', borderRight: index == 1 ? '1px solid #e0e0e0' : '' }"
                                v-for="(i, index) in sortList" :key="index">
                                <div style="display: flex;align-items: center;justify-content: center;">
                                    <div>{{ i.name }}</div>
                                    <div v-if="index == 1" style="display: flex;align-items: center;margin-left: 5px;">
                                        <img :src="imagePath" style="width: 14px;" alt="">
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="my">共找到 <span style="color: #FF4242;">{{ paging.total }}</span> 条 {{ keyWords }} 产品信息
                        </div>
                    </div>
                </div>

                <!-- 商品 -->
                <div class="tuijian" v-loading="loading">
                    <div class="son" v-for="(i, index) in goodsList" :key="index" @click="detailsClick(i)">
                        <div class="img">
                            <img :src="i.mainImage" alt="">
                        </div>
                        <div class="name" v-html="i.title?.replace(/color=#f4511e/g, 'color:rgb(244, 81, 30) ')"></div>
                        <div class="jiage">
                            <div class="l" v-if="i.spuType == 'discuss' || i.price == 0">￥面议</div>
                            <div class="l" v-else>￥{{ i.price }}</div>
                            <div v-if="i.type == '2'"
                            style="width: 40px;height: 20px;border-radius: 4px;text-align: center;line-height: 20px;font-size: 12px;color: #fff;background-color: #FF4242;margin-right: 5px;">
                            定金</div>
                            <div class="r">销量 {{ i.sales }}</div>
                        </div>
                        <div style="font-size:14px;margin-top:5px;">
                            <div v-html="i.storeName?.replace(/color=#f4511e/g, 'color:rgb(244, 81, 30) ')"></div>
                        </div>
                    </div>
                </div>

                <paging :total="paging.total" @handleCurrentChange="handleCurrentChange" :page-size="paging.sizi"
                    :currentPage="paging.page"></paging>
            </div>
        </div>


    </div>
</template>

<script>
import { getGoodsSearchKeyword, getGoodsCategoryParamer, getGoodsSearchBrand, getStoreHotSales } from "@/utils/api/homeApi/index"
import noResults from "@/pages/searchPage/noResults.vue";
import paging from "@/components/paging.vue";
export default {
    name: 'search2',
    components: {
        noResults,
        paging
    },
    data() {
        return {
            sortList: [
                {
                    name: '综合',
                    act: true
                },
                {
                    name: '价格',
                    act: false
                },
                {
                    name: '销量',
                    act: false
                }
            ],
            // 分类名称
            typeName: '',
            paging: {
                sizi: 50,
                page: 1,
                total: 0
            },
            type: '',
            // 商品列表
            goodsList: [],
            // 
            loading: true,
            loading2: true,
            // 价格logo
            imagePath: require('@/assets/icon/jgpx.png'),
            // 展开收起
            zkImg: require('@/assets/icon/zk.png'),
            // 关键词
            keyWords: '',
            keyWords2: '',
            // 分类
            typeList: [],
            // 是否展开
            aFoldShow: false,
            // 搜索类型
            typeSouS: '',
            brandType: 'ALL',
            // 优惠券id
            storeId: '',
            // 指定商品优惠券id
            spuIds:'',
            lengthIndex:1
        }
    },
    mounted() {
        this.keyWords = this.$route.query.keyWords
        if (this.$route.query.type) {
            this.typeSouS = this.$route.query.type
            this.storeId = this.$route.query.storeId
            this.spuIds = this.$route.query.spuIds
        }
        this.getGoodsType()
    },
    methods: {
        sortCilck(index) {
            this.sortList.map((t) => (t.act = false));
            this.sortList[index].act = true;
            if (index == 0) {
                this.type = ''
                this.brandType = 'ALL'
                this.imagePath = require('@/assets/icon/jgpx.png')
            }
            if (index == 1) {
                if (this.type == 'lowest' || this.type == 'highest') {
                    if (this.type == 'lowest') {
                        this.type = 'highest'
                        this.imagePath = require('@/assets/icon/jgpxb.png')
                    } else {
                        this.type = 'lowest'
                        this.imagePath = require('@/assets/icon/jgpxt.png')
                    }
                } else {
                    this.type = 'lowest'
                    this.imagePath = require('@/assets/icon/jgpxt.png')
                }

                if (this.brandType == 'LOW' || this.brandType == 'TALL') {
                    if (this.brandType == 'LOW') {
                        this.brandType = 'TALL'
                        this.imagePath = require('@/assets/icon/jgpxb.png')
                    } else {
                        this.brandType = 'LOW'
                        this.imagePath = require('@/assets/icon/jgpxt.png')
                    }
                } else {
                    this.brandType = 'LOW'
                    this.imagePath = require('@/assets/icon/jgpxt.png')
                }
            }
            if (index == 2) {
                this.type = 'sales'
                this.brandType = 'SALES'
                this.imagePath = require('@/assets/icon/jgpx.png')
            }
            this.loading = true
            this.loading2 = true
            this.paging.page = 1
            this.getGoodsType()
        },
        aFold() {
            this.aFoldShow = !this.aFoldShow
            if (this.aFoldShow) {
                this.$refs.brandDead.style.transition = 'all .3s';
                this.$refs.brandDead.style.maxHeight = this.$refs.brandDead.scrollHeight + "px";
                this.zkImg = require('@/assets/icon/sq.png')
            } else {
                this.$refs.brandDead.style.transition = 'all .3s';
                this.$refs.brandDead.style.maxHeight = '200px'
                this.zkImg = require('@/assets/icon/zk.png')
            }
        },
        // 点击分类事件
        tabsClick(item, dInfo) {
            this.typeList.map(t => {
                t.option.map(d => {
                    if (d.label == dInfo.label && item.id == t.id) {
                        d.choose = !d.choose
                    }
                })
            })
            let typeArr = []
            this.typeList.forEach(y => y.option.forEach(b => {
                if (b.choose) {
                    typeArr.push(b.label)
                }
            }))
            this.keyWords2 = typeArr.join(',')
            console.log(typeArr)
        },
        // 筛选按钮
        screen() {
            this.paging.page = 1
            this.loading2 = true
            this.loading1 = true
            this.getGoodsType()
        },
        // 分页事件
        handleCurrentChange(val) {
            this.loading = true
            this.paging.page = val
            this.getGoodsType()
        },
        // 根据分类获取商品
        async getGoodsType() {
            if (this.typeSouS == 'brand') {
                let data = {
                    keyWord: this.keyWords,
                    sPage: this.paging.page,
                    size: this.paging.sizi,
                    type: this.brandType
                }
                const res = await getGoodsSearchBrand(data)
                console.log(res, 'barand')
                if (res.statusCode == 8201) {
                    this.goodsList = res.data.records
                    
                    this.paging.total = res.data.total
                    this.loading = false
                    if (res.data.records.length == 0) {
                        this.loading2 = false
                    } else {
                        this.getCategoryParamer(res.data.records[0].categoryId)
                    }
                }
            } else if (this.typeSouS == 'coupon') {
                let data = {
                    storeId: this.storeId,
                    currentPage: this.paging.page,
                    pageSize: this.paging.sizi,
                    type: this.brandType,
                    spuIds : this.spuIds
                }
                const res = await getStoreHotSales(data)
                if (res.statusCode == 8201) {
                    this.goodsList = res.data.records
                    this.paging.total = res.data.total
                    this.loading = false
                    if (res.data.records.length == 0) {
                        this.loading2 = false
                    } else {
                        this.getCategoryParamer(res.data.records[0].categoryId)
                    }
                }
            } else {
                let data = {
                    keyWord: `${this.keyWords},${this.keyWords2}`,
                    sPage: this.paging.page,
                    size: this.paging.sizi,
                    mold: this.type
                }
                const res = await getGoodsSearchKeyword(data)
                if (res.statusCode == 8201) {
                    if(res.data.records.length == 0){
                        if(this.lengthIndex == 0) return
                        this.lengthIndex--
                        this.getGoodsType()
                    }
                    this.goodsList = res.data.records
                    this.paging.total = res.data.total
                    this.loading = false
                    this.loading2 = false
                    if (res.data.records.length == 0) {
                    } else {
                        this.getCategoryParamer(res.data.records[0].categoryId)
                    }
                }

            }
            console.log(this.goodsList)
        },
        // 查询分类
        async getCategoryParamer(ids) {
            const res = await getGoodsCategoryParamer(ids)
            if (res.statusCode == 8201) {
                if (this.typeList.length == 0) {
                    this.typeList = res.data.paramenters
                }
                this.loading2 = false
                console.log(this.loading2)
            }
        },
        // 商品详情页
        detailsClick(i) { 
            window.open(`#/commodity/productDetails?ids=${i.spuId || i.id}`, '_blank');
        },
    }
}
</script>

<style lang="less" scoped>
.home {
    background-color: #f4f4f4;
    margin-top: 3px;

}

.breadcrumb {
    width: 1200px;
    margin: 0px auto;
    padding: 20px;
    box-sizing: border-box;
}

// 删选样式 tab
.brandDead {
    width: 1200px;
    max-height: 200px;
    overflow: hidden;
    transition: all .3s;
    margin: 35px auto;
    background-color: #fff;
    padding: 10px 20px 20px 20px;
    box-sizing: border-box;
    border: 1px solid #E0E0E0;

    >.son {
        width: 100%;
        height: auto;
        font-size: 14px;
        display: flex;
        padding-bottom: 15px;
        border-bottom: 1px solid #E0E0E0;
        margin-top: 12px;
        align-items: center;

        >.title {
            width: 75px;
            color: #666;
            margin-right: 20px;
        }

        >.tabs {
            width: calc(100% - 75px);
            color: #333;
            display: flex;
            flex-wrap: wrap;

            >.tab {
                margin-right: 20px;
                transition: all .3s;
                cursor: pointer;
            }

            >.tab:hover {
                color: #FF4242;
                transition: all .3s;
            }
        }

    }
}

// 价格排序tabs
.sorting {
    width: 1200px;
    height: auto;
    margin: 20px auto;
    border-radius: 4px;
    background-color: #F5F5F5;
    padding: 0px 10px;
    box-sizing: border-box;

    >.tabsBox {
        width: 100%;
        height: 45px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #F5F5F5;

        >.tabs {
            display: flex;
            border: 1px solid #E0E0E0;
            background-color: #fff;
            box-sizing: border-box;
            color: #333;


            >.son {
                width: 84px;
                height: 30px;
                box-sizing: border-box;
                // border: 1px solid #fff;
                text-align: center;
                line-height: 30px;
                font-size: 14px;
                text-align: center;
                transition: all .3s;
                cursor: pointer;
            }
        }

        >.my {
            font-size: 14px;
            color: #333;
        }
    }
}

// 商品
.tuijian {
    width: 1200px;
    height: auto;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;

    >.son {
        width: 220px;
        height: auto;
        background-color: #fff;
        border-radius: 4px;
        margin: 0 10px;
        margin-bottom: 20px;
        padding: 10px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        box-sizing: border-box;
        transition: all .3s;
        cursor: pointer;

        >.img {
            width: 200px;
            height: 200px;

            >img {
                width: 100%;
                height: 100%;
            }
        }

        >.name {
            font-size: 14px;
            color: #000;
            width: 100%;
            margin-top: 10px;
            white-space: nowrap;
            transition: all .3s;
            /* 禁止换行 */
            overflow: hidden;
            /* 超出部分隐藏 */
            text-overflow: ellipsis;
            /* 超出部分显示省略号 */
        }

        >.jiage {
            width: 100%;
            height: 25px;
            margin-top: 5px;
            display: flex;
            align-items: flex-end;

            >.l {
                font-size: 16px;
                font-weight: bold;
                color: #FF4242;
                margin-right: 6px;
            }

            >.r {
                font-size: 12px;
                color: #666;
                margin-bottom: 2px;
            }
        }
    }

    >.son:hover {
        border: 1px solid #FFA5A5;
        transition: all .3s;

        >.name {
            color: #FF4242;
            transition: all .3s;
        }
    }
}

// 分页
.paging {
    width: 1200px;
    height: 50px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
}


.tabsAct {
    color: #ff4242;
    font-weight: 700 !important;
    transition: all 0.3s;
}

.tabsAct2 {
    background-color: #FF4242;
    color: #fff;
    transition: all .3s;
}

/deep/ .el-breadcrumb__inner.is-link:hover {
    color: #ff4242 !important;
}


// 分页样式
/deep/ .el-pagination .active {
    background-color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-pagination:hover .active:hover {
    color: #fff !important;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff;
    border: 1px solid #fff;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #ff4242;
    border: 1px solid #ff4242;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-right:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-left:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}
</style>